<template>
  <div>
    <div id="msg" v-if="expectedEncoding">
      <div
        :class="expectedEncodingClass()"
        v-text="expectedEncodingClass()==='good'?'MATCH!':'NO MATCH!'"
      ></div>
    </div>

    <table id="tbl">
      <tbody>
        <tr v-for="(val,key) in encs">
          <td class="head" style="text-transform: capitalize;">{{ key }}</td>
          <td>
            <input
              type="text"
              spellcheck="false"
              v-model="encs[key]"
              @change="setEnc($event.target.value,key)"
              :class="encodingClass(key)"
              @input="clearQ()"
            />
          </td>
          <td class="rightCol" @click="copyText($event)">
            COPY
          </td>
        </tr>
        <tr v-if="expectedEncoding">
          <td class="head">Expected</td>
          <td>
            <input readonly="readonly" :value="expectedEncoding" :class="expectedEncodingClass()" />
          </td>
          <td class="rightCol" @click="copyText($event)">
            COPY
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import base58checker from "base58check";
import bs58 from "bs58";
import base64urler from "base64url";

const base64url = "base64url";
const base64 = "base64";
const hex = "hex";

export default {
  name: "encodings",
  props: ["q"],
  mounted() {
    for (let enc in this.encs) {
      if (this.q[enc]) {
        this.setEnc(this.q[enc], enc);
      }
    }
  },
  data: function() {
    return {
      err: "",
      buf: Buffer.from(""),
      expectedEncoding: this.q.expected ? this.q.expected : ""
      // checkMatch: false
    };
  },

  watch: {
    buf: function(val, oldVal) {
      this.err = "";
    }
  },
  computed: {
    encs: function() {
      return {
        hex: this.buf2enc(this.buf, hex),
        base64: this.buf2enc(this.buf, base64),
        base64url: this.buf2enc(this.buf, base64url),
        base58: this.buf2enc(this.buf, "base58"),
        base58check: this.buf2enc(this.buf, "base58check"),
        text: this.buf2enc(this.buf, "text")
      };
    }
  },
  methods: {
    expectedEncodingClass: function() {
      return Object.values(this.encs).includes(this.expectedEncoding)
        ? "good"
        : "err";
    },
    encodingClass: function(enc) {
      if (this.err === enc) return "err";
      if (!this.expectedEncoding) return "";
      if (this.q.expected === this.encs[enc]) return "good";
      if (this.q.hasOwnProperty(enc)) return this.expectedEncodingClass();
      return "";
    },
    buf2enc: function(buf, enc) {      
      switch (enc) {
        case hex:
        case base64:
          return buf.toString(enc);
        case base64url:
          return base64urler.encode(buf);
        case "base58":
          return bs58.encode(buf);
        case "base58check":
          return 0 === buf.length ? "" : base58checker.encode(buf);
        case "text":
          return buf.toString();
      }
    },
    enc2buf: function(txt, enc) {
      switch (enc) {
        case hex:
        case base64:
          return Buffer.from(txt, enc);
        case base64url:
          return Buffer.from(base64urler.decode(txt));
        case "base58":
          return Buffer.from(bs58.decode(txt));
        case "base58check":
          return base58checker.decode(txt).data;
        case "text":
          return Buffer.from(txt);
      }
    },
    clearQ: function() {
      this.expectedEncoding = "";
    },
    setEnc: function(txt, enc) {
      if (enc === "hex") txt = txt.toLowerCase();
      try {
        const b = this.enc2buf(txt, enc);
        if (this.buf2enc(b, enc) === txt) {
          this.buf = b;
        } else {
          this.err = enc;
        }
      } catch (e) {
        this.err = enc;
      }
    },

copyText: function(e) {
      const buttons = document.querySelectorAll(".rightCol");
      let btn = e.target;
      let textToCopy = btn.parentElement.children[1].firstElementChild;
      
      if(textToCopy.value.length < 1) {
        alert("Copy Failed. Textfield is empty")
        return;
      }

      buttons.forEach(button => {
        if (button.textContent === "COPIED") {
          button.textContent = "COPY";
          button.classList.remove("copied");
        }
      });

      textToCopy.select();
      document.execCommand("copy");
      btn.classList.add("copied");
      btn.textContent = "COPIED";
      textToCopy.focus();
      textToCopy.setSelectionRange(1000, 1000);
    }
  }
}
</script>

<style scoped>


.head {
  background: #03af84;
  padding: 10px;
  width: 30px;
    text-transform: capitalize;
  text-align: right;
}

.good {
  color: var(--brand-green);
}

.err {
  color: lightcoral;
}

.copied {
  background: var(--brand-green) !important;
}

table tbody .err {
  color: lightcoral;
}

table tfoot .err {
  color: lightcoral;
}

table tbody .good {
  color: var(--brand-green);
}

table tfoot .good {
  color: var(--brand-green);
}

div {
  text-align: center;
}

#tbl {
  max-width: 750px;
  width: 80%;
  margin: 0 auto;
  background: #385366;
  border: 1px solid white;
  border-radius: 5px;
  border-collapse: collapse;
  border-style: hidden;
  box-shadow: 0 0 0 1px;
}

td {
  border: 1px solid #fff;
}


#tbl tbody tr:last-child td:first-child {
    border-radius: 0 0 0 5px;
}
#tbl tbody tr:first-child td:first-child {
    border-radius: 5px 0 0 0;
}
#tbl tbody tr:last-child td:last-child {
    border-radius: 0 0 5px 0;
}
#tbl tbody tr:first-child td:last-child {
    border-radius: 0 5px 0 0;
}

input {
  width: 99%;
  font-family: monospace;
  font-size: 1.6ch;
  background: #385366;
  outline: none;
  border: none;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#msg {
  font-size: 4ch;
  padding-bottom: 20px;
}

#msg td {
  text-align: center;
  padding-bottom: 1ch;
}

.rightCol {
  width: 50px;
  background-color: #6a7e8c;
  transition: background-color 0.25s ease-out;
  transition-duration: 0.5s;
  transition-property: background-color;
  font-size: 0.8rem;
  cursor: pointer;
  box-sizing: border-box;
}
.rightCol:hover {
  background: #e6eaec;
  color: #03af84;

}

.rightCol.copied:hover {
  background: #e6eaec !important;
  color: #03af84;
}
</style>
